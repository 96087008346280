import { useEffect } from 'react';
import { parse } from 'cookie';
import { getCookie, deleteCookie } from 'cookies-next';

export const auth = (ctx, isServerRendered, redirect) => {
  let session;
  let sessionCreatedAt;
  if (isServerRendered) {
    const cookies = parse(ctx.req.headers.cookie || ''); // Use parse function to extract cookies
    session = cookies['mycap:auth0:session'];
    sessionCreatedAt = cookies['sessionCreatedAt'];
  } else {
    session = getCookie('authenticated'); // Use getCookie function to get cookie value
    sessionCreatedAt = getCookie('sessionCreatedAt');
  }

  /*
   * If `ctx.req` is available it means we are on the server.
   * Additionally if there's no token it means the user is not logged in.
   */
  if (ctx.req && !session) {
    if (redirect) {
      ctx.res.writeHead(302, { Location: '/api/login?redirect=' + encodeURIComponent(ctx.req.url) });
      ctx.res.end();
    }
    return { authenticated: false, sessionCreatedAt: null };
  }

  // We already checked for server. This should only happen on client.
  if (!session) {
    if (redirect) {
      window.location = '/api/login?redirect=' + encodeURIComponent(window.location.href?.replace(window.location.origin, ''));
    }
    return { authenticated: false, sessionCreatedAt: null };
  }
  return {
    authenticated: true,
    sessionCreatedAt: sessionCreatedAt || null,
  };
};

export const logout = () => {
    // to support logging out from all windows
    deleteCookie(null, 'authenticated'); // Use destroyCookie function to remove the cookie
    var apiUrl = '/api';
    if (process && process.env && process.env.API_URL) {
      apiUrl = process.env.API_URL;
    }
    window.location.assign(apiUrl + '/logout');
  };

// Rest of your code remains unchanged

const makeWrapper = (WrappedComponent) => {
    const Wrapper = props => {
        const syncLogout = event => {
            if (event.key === 'logout') {
                console.log('logged out from storage!')
                window.location.assign("/api/logout")
            }
        }
      
        useEffect(() => {
            // if were in the browser, and this page was rendered by the server
            // then lets set the authenticated value into window
            if (props.isServerRendered) {
                window.__NEXT_DATA__.authenticated = props.authenticated
            }


            window.addEventListener('storage', syncLogout)
    
            return () => {
                window.removeEventListener('storage', syncLogout)
                window.localStorage.removeItem('logout')
            }
        }, [])
      
        return <WrappedComponent {...props} />
    }

    return Wrapper;
}

const setWrapperInitalProps = (WrappedComponent, Wrapper, redirect) => {
    Wrapper.getInitialProps = async ctx => {
        // true if obj {}, false if undefined
        const isServerRendered = Boolean(ctx.req);

        const { authenticated, sessionCreatedAt } = auth(ctx, isServerRendered, redirect)

        const componentProps =
            WrappedComponent.getInitialProps &&
            (await WrappedComponent.getInitialProps(ctx))

        return { ...componentProps, authenticated, sessionCreatedAt, isServerRendered }
    }
}

export const protectRoute = WrappedComponent => {
    const Wrapper = makeWrapper(WrappedComponent);
    const redirect = true;
    setWrapperInitalProps(WrappedComponent, Wrapper, redirect);
    return Wrapper
}

export const checkAuthRoute = WrappedComponent => {
    const Wrapper = makeWrapper(WrappedComponent);
    const redirect = false;
    setWrapperInitalProps(WrappedComponent, Wrapper, redirect);
    return Wrapper
}